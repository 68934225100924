// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Information from "../components/Information";
import Layout from "../components/Layout";
import RichText from "../components/RichText";

const PageTemplate = ({ pageContext, data }) => {

  const { title, body } = data.contentfulPage;

  return (
    <Layout>
      <article>
        <RichText {...body} />
      </article>
      <Information />
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const PageTemplatePageQuery = graphql`
  query PageTemplateQuery($slug: String!) {
    contentfulPage(slug: {eq: $slug}) {
      title
      slug
      body {
        json
      }
    }
  }
`
